.login {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding-bottom: 30px;
	padding-top: 50px;
	margin-top: 20px;
	background: rgb(255, 231, 255);
	border-radius: 10px;
	background: linear-gradient(
		303deg,
		rgba(255, 231, 255, 1) 0%,
		rgba(230, 255, 243, 1) 53%,
		rgba(242, 252, 255, 1) 100%
	);
	margin-bottom: -30px;

	.special-color {
		font-weight: 700;
		border-radius: 10px;
	}

	img {
		height: 50px;
		width: auto;
		margin-bottom: 30px;
	}

	button {
		background: #ffffff;
		color: #000;
		cursor: pointer;
		border: none;
		width: 250px;
		height: 40px;
		border-radius: 3px;
		margin: 5px 0px;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 10px;
	}

	.new-account-msg {
		margin-bottom: 30px;
		// background-color: #d7ffec;
		padding: 3px 10px;
		border-radius: 5px;
	}

	form {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 10px;
		background-color: white;
		padding: 30px;
		max-width: 90%;
		border-radius: 5px;
		box-shadow: 0px 0px 10px #d069ea5e;

		input,
		select {
			height: 40px;
			color: black;
			width: 250px;
			padding-left: 5px;
			border: 1px solid rgb(211, 211, 211);
			border-radius: 3px;
			background-color: #fff;
			max-width: 100%;
		}

		label {
			max-width: 100%;
		}

		.login-btn {
			background-color: #afffd9;
			max-width: 100%;
		}

		.label-wrapper {
			display: flex;
			flex-direction: column;
			gap: 5px;
		}
	}
	.error-msg {
		height: 25px;
		margin-top: 15px;
		margin-bottom: -5px;
		color: #ff0000;
	}

	.create-account,
	.reset-password {
		text-decoration: underline;
		background: none;
		box-shadow: none;
	}

	.reset-password {
		text-decoration: none;
		margin: 0px;
		margin-top: 10px;
	}
}
